@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 97.65%;
    --foreground: 0 0% 12.55%;
    --muted: 0 0% 93.73%;
    --muted-foreground: 0 0% 39.22%;
    --popover: 0 0% 98.82%;
    --popover-foreground: 0 0% 12.55%;
    --card: 0 0% 98.82%;
    --card-foreground: 0 0% 12.55%;
    --border: 0 0% 84.71%;
    --input: 0 0% 94.12%;
    --primary: 205 98% 20%;
    --primary-foreground: 0 0% 96%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 205 98% 20%;
    --accent: 0 0% 90.98%;
    --accent-foreground: 0 0% 12.55%;
    --destructive: 10.16 77.87% 53.92%;
    --destructive-foreground: 0 0% 100%;
    --ring: 3 85% 57%;
    --radius: 0.5rem;

    --chart-1: 214 76% 19%;
    --chart-2: 191 95% 43%;
    --chart-3: 15 97% 60%;
    --chart-4: 50 94% 49%;
    --chart-5: 139 23% 47%;

    --dodger-blue: 240 100% 66%;
    --accent-red: 4 85% 57%;

    --sidebar-background: 0 0% 98.82%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 0 0% 90.98%;
    --sidebar-accent-foreground: 0 0% 12.55%;
    --sidebar-border: 0 0% 84.71%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    --background: 210 31% 6%;
    --foreground: 220 31% 69%;
    --muted: 210 12% 15%;
    --muted-foreground: 210 12% 65%;
    --popover: 210 31% 3%;
    --popover-foreground: 220 31% 79%;
    --card: 210 31% 4%;
    --card-foreground: 220 31% 74%;
    --border: 210 21% 11%;
    --input: 210 21% 11%;
    --primary: 3 86% 60%;
    --primary-foreground: 33 100% 11%;
    --secondary: 2 63% 45%;
    --secondary-foreground: 33 30% 85%;
    --accent: 210 31% 21%;
    --accent-foreground: 210 31% 81%;
    --destructive: 4 87% 57%;
    --destructive-foreground: 0 0% 100%;
    --ring: 3 85% 57%;

    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 12 88% 56%;
    --chart-4: 55 100% 68%;
    --chart-5: 37 74% 85%;

    --radius: 0.4rem;
    --accent-red: 4 85% 57%;
    --dodger-blue: 240 100% 66%;

    --sidebar-background: 210 31% 4%;
    --sidebar-foreground: 220 31% 69%;
    --sidebar-primary: 3 86% 60%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 210 31% 21%;
    --sidebar-accent-foreground: 210 31% 81%;
    --sidebar-border: 210 21% 11%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

::selection {
  background: hsl(var(--primary));
  color: hsl(var(--background));
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer utilities {
  .no-scrollbar::webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
